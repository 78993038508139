<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../../../assets/partners/banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("company.partners.banner_title")}}</h2>
      </div>
    </div>
    <section class="info-container">
        <div class="text text-title">{{t("company.partners.parteners_title")}}</div>
        <div class="text">{{t("company.partners.parteners_dec")}}</div>
        <div class="info-list">
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/amazon.png" alt="">
              <div class="item-text">Amazon</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/bing.png" alt="">
              <div class="item-text">Bing</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/facebook.png" alt="">
              <div class="item-text">Facebook</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/google.png" alt="">
              <div class="item-text">Google</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/lazada.png" alt="">
              <div class="item-text">Lazada</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/linkedin.png" alt="">
              <div class="item-text">Linkedin</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/microsoft.png" alt="">
              <div class="item-text">Microsoft</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/shopify.png" alt="">
              <div class="item-text">Shopify</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/shopify2.png" alt="">
              <div class="item-text">Shopify</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/tiktok.png" alt="">
              <div class="item-text">Tiktok</div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <img src="../../../../assets/partners/twitter.png" alt="">
              <div class="item-text">Twitter</div>
            </div>
          </div>
        </div>
        
        <!-- <div class="text text-title">{{t("company.partners.join_title")}}</div>
        <div class="text">{{t("company.partners.join_dec")}}</div>
        <div class="btn"><button>{{t("company.partners.join_btn")}}</button></div> -->
    </section>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
export default {
    name: 'PARTNERS',
    components: {},
    setup() {
        const { t } = useI18n();
        return {
          t,
        };
    },
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 793px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      font-weight: 400;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
  }

  .info-container {
      background: url(../../../../assets/partners/partners-bg.png) no-repeat;
      // background-size: cover;
      min-height: 500px;
      padding: 80px 0;
    .text {
        width: 1140px;
        text-align: center;
        margin: 0 auto;
        font-size: 18px;
        color: #333333;
        line-height: 40px;
        &.text-title {
            font-size: 30px;
            line-height: 40px;
            padding: 0 100px;
            // margin-top: 80px;
            margin-bottom: 20px;
        }
    }
    .btn {
      width: 1140px;
      text-align: center;
      margin: 20px auto;
      font-size: 18px;
      button {
        padding: 10px 30px;
        color: #ffffff;
        background: #FF9333;
        border: 1px solid #FF9333;
        border-radius: 20px;
        cursor: pointer;
      }
    }
    .info-list {
      width: 1140px;
      text-align: center;
      margin: 80px auto 0;
      font-size: 18px;
      color: #333333;
      display: flex;
      flex-wrap: wrap;
      .item {
        // flex: 1;
        width: 33%;
        padding: 0 10px 20px;
        .content {
          width: 100%;
          font-size: 0;
          background: #ffffff;;
          box-shadow: 0px 0px 20px 0px rgba(16, 81, 170, 0.1);
          img {
            width: 100%;
          }
          .item-text {
            line-height: 60px;
            background: #E7E7E7;
            font-weight: 600;
            color: #333333;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
